<template>
  <el-dialog title="RAKUMART user agreement and privacy policy" :visible.sync="Userprotocol" width="1400px"
    :before-close="handleClose">
    <div class="main">
      <h2>Privacy policy</h2>
      <p> The RAKUMART platform (referred to as "The Platform""We”hereinafter) respects and protects the privacy of all
        users
        using the service. In order to provide you with more accurate and personalized services, The Platform will use and
        disclose your personal information in accordance with this Privacy Policy. However, The Platform will handle
        the information cautiously. Unless otherwise provided for in this Privacy Policy, The Platform will not disclose
        or
        provide this information to third parties without your prior permission. The Platform will update this Privacy
        Policy from time to time. Agree to The Terms of Service, which means that you have agreed to the whole content of
        this Privacy Policy. This Privacy Policy is an integral part of the Terms of Service on RAKUMART.</p>
      <p> We, RAKUMART take your personal information seriously and use or keep it confidential. In order to protect your
        private information, we have settled this Privacy Policy (the "Policy") in accordance with best practices in the
        industry and applicable laws and regulations. This Policy describes how we handle your personal information for
        our
        services on RAKUMART. We may amend this Policy at any time by posting the amended terms on The Platform.</p>
      <p class="marbottom40"> By accepting the Policy and the User Agreement in registration, you expressly
        consent to our collection,
        storage,
        use and disclosure of your personal information as described in this Policy.</p>

      <h3>Scope of Application</h3>
      <p> 1.You need to provide personal informationduring registrationaccording to the requirements of The Platform;</p>
      <p> 2. When you visit the web page of The Platform, The Platformautomatically receives and records information from
        your
        interaction with The Platform,services, content and advertising, including computer and connection information,
        statistics on page views, traffic to and from the Website, ads data, IP address and standard web log information;
      </p>
      <p> 3. Other supplemental information from third parties;</p>
      <p> 4. Keywordsand other information you provide to purchase or dispatch an item;</p>
      <p class="marbottom40">5. We may combine your information with information we collect from other companies and use
        it to improve and
        personalizeour services, content and advertising.
      </p>

      <h3>Use of Information</h3>
      <p> 1.We don't provide, sell, rent, share and transactyour personal information to third parties for their marketing
        purposes without your explicit consent;</p>
      <p> 2.Under no circumstance will allow thethird party to collect, edit, sell, or spreadyour personal information.
        Once
        users are found to engage in the above activities, The Platform has the right to terminate the services with the
        users immediately.</p>
      <p class="marbottom40"> 3.Our primary purpose in collecting personal information is to provide you with a safe,
        smooth, efficient and
        customized experience. You agree that we may use your personal information tosend you targeted marketing, service
        updates, and promotional offers.

      </p>
      <h3>Disclosure of Your Information</h3>
      <p>We may disclose personal information to respond to legal requirements, such information will be disclosed in
        accordance with applicable laws and regulations. </p>
      <p class="marleft">· Third parties to whom you explicitly ask us to send your information/ you consent to when using
        a specific service;</p>
      <p class="marleft">·Third-party service providers under contract who help with our business operations;</p>
      <p class="marleft">·Law enforcement or other governmental officials, in response to a verified request relating to a
        criminal
        investigation or alleged illegal activity;</p>
      <p class="marleft">·Third parties when you violate the applicablelaw, regulations and Terms of Use of RAKUMART;</p>
      <p class="marleft">· The complainant when you file a complaint so that both parties may handle possible rights
        disputes;</p>
      <p class="marbottom40 marleft">·
        In the transaction created on The Platform, if any party to the transaction has fulfilled or partially fulfilled
        the
        transaction obligations and required information disclosure, The Platformhas the right to decide to provide users
        with contact information of the counterparty, and other information conducive to complete the transaction or to
        the
        dispute settlement.</p>
      <h3>Information Storage and Communication</h3>
      <p class="marbottom40">Your information will be stored on the serversof RAKUMARTand/or its affiliates which may be
        transmitted to your country, region or locations we have collected. The information can be accessed, stored, and
        displayed abroad.</p>
      <h3>Cookies</h3>
      <p> 1.We use "cookies" (small files placed on your hard drive) on certain of our pages to help analyze our web page
        flow; customize our services, content and advertising; measure promotional effectiveness, and promote trust and
        safety;</p>
      <p> 2.You are always free to decline our cookies if your browser permits, although doing so may interfere with your
        use
        of some of our sites or services;</p>
      <p calss="marbottom40">
        3.This policy will apply to information obtained through cookies provided in The Platform.</p>
      <h3>Security</h3>
      <p> 1.Your password is the key to your account. Use unique numbers and letters and do not disclose your password to
        anyone.The Platformwill ensure that your information will not be lost, abused or changed through security measures
        such as encrypted passwords. Despite the above security measures, please pay attention to that there is no
        "perfect
        security measure" in the information era.</p>
      <p class="marbottom40"> 2.When trading online on The Platform, you will inevitably transactwith tradersor potential
        trading partners.</p>
      <h3>
        Amendment of the Policy
      </h3>
      <p> 1.We may amend this Policy at any time by posting the amended terms on this Website.</p>
      <p class="marbottom40"> 2.RAKUMARTreserves the right to amendthis policy at any time, so please check our
        website frequently. If
        there are any significant changes, it will be highlighted on the web page.

      </p>
      <h3>General</h3>
      <p> 1.Be cautious aboutyour personal information such as contact information or postal address. Do not disclose it
        to
        others unless it’s necessary. If you believethat your personal information has been leaked, especially when your
        password has been compromised for any reason, you should immediately notify us and change your password. </p>
      <p class="marbottom40"> 2.The right of final interpretation of this Privacy Policy belongs to RAKUMART

      </p>
      <p style="text-align: right;">Effective Date: now</p>
      <p style="text-align: right;">Validity Period: Long-term validity</p>






    </div>
    <span slot="footer" class="dialog-footer">
      <el-button class="agree" @click="agree">{{ $fanyi('同意') }}</el-button>
      <el-button class="cancel" @click="handleClose">{{
        $fanyi('取消')
      }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: 'useragreement',

  props: {
    Userprotocol: {
      require: true,
      default: false,
    },
  },
  data() {
    return {
      dialogVisible: false,
    }
  },

  methods: {
    handleClose() {
      this.$emit('clonseUserprotocol', false)
    },
    agree() {
      this.$emit('clonseUserprotocol', true)
    },
  },
}
</script>

<style lang="scss" scoped>
// @import '../../../css/mixin.scss';
/deep/.el-dialog__header {
  border-bottom: solid 1px #ededed;
  min-height: 60px;
  line-height: 60px;
  font-size: 32px !important;
  padding-left: 30px !important;
  font-weight: 500;
  font-family: 'Roboto-Regular,Roboto';
  // font-size: 50px;
}

.agree {
  min-width: 134px;
  height: 46px;
  text-align: center;
  background-color: #ff730b;
  color: #fff;
  border-radius: 6px;
  font-size: 18px;
  margin-top: 25px;
  border: none;
}

.cancel {
  min-width: 134px;
  height: 46px;
  text-align: center;
  margin-top: 20px;
  background-color: #fff;
  color: #333333;
  border: 1px solid #dcdfe6;
  border-radius: 6px;
  font-size: 18px;
  margin-left: 25px;
}

/deep/ .el-dialog__footer {
  display: flex;
  height: 100px;
  justify-content: center;
}

.main {
  // width: 1000px !important;
  height: 500px;
  overflow: auto;
  padding: 40px 30px;

  h2 {
    width: 100%;
    font-size: 30px;
    text-align: center;
    font-weight: bold;
    margin-bottom: 43px;

  }

  h3 {
    font-size: 21px;
    font-family: Roboto-Medium, Roboto;
    font-weight: 500;
    color: #222222;
    line-height: 24px;
    margin-bottom: 20px;
  }

  h4 {
    font-size: 16px;
    font-family: Roboto-Bold, Roboto;
    font-weight: bold;
    color: #222222;
    line-height: 24px;
    margin-bottom: 20px;
  }

  p {
    font-size: 16px;
    font-family: Roboto-Regular, Roboto;
    font-weight: 400;
    color: #222222;
    line-height: 24px;
    margin-bottom: 8px;
  }

  .marleft {
    padding-left: 20px;
    margin-bottom: 10px;
  }

  .marbottom40 {
    margin-bottom: 40px;
  }
}
</style>
