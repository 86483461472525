<template>
  <div class="successbox" :class="{ isopen: openBlock }">
    <h1 class="successboxh1" @click="openBlock = !openBlock">
      <span>{{ title }}</span>
      <div class="btnImg">
        <img src="../../../assets/icon/Vector.png" :class="{ isopen: openBlock }" alt="" />
      </div>
    </h1>
    <slot></slot>
  </div>
</template>
<script>
export default {
  props: {
    title: String,
  },
  data() {
    return { openBlock: false }
  },
  components: {},
  computed: {},
  created() {

  },
  methods: {},
}
</script>
<style lang="scss" scoped>
@import '../../../css/mixin.scss';

.successbox {
  width: 800px;
  height: 68px;
  background: #ffffff;
  border: 1px solid #e2e2e2 !important;
  border-radius: 6px;
  //border-radius: 4px;
  margin-bottom: 20px;
  padding: 26px 30px 20px 30px;
  overflow: hidden;
  transition: 0.3s;

  &.isopen {
    height: unset;
  }

  .successboxh1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    cursor: pointer;

    span {
      font-size: 18px;
      line-height: 18px;
      font-family: Open Sans;
      font-weight: bold;
      color: #2f2f2f;
    }

    .btnImg {
      min-width: 30px;
      height: 18px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 13px;
        height: 8px;
        transform: rotate(180deg);
        transition: 0.3s;
        cursor: pointer;

        &.isopen {
          transform: rotate(0deg);
        }
      }

      span {
        display: inline-block;
        min-width: 206px !important;
        height: 24px;

        font-size: 16px;
        font-family: PingFang-SC-Regular, PingFang-SC;
        font-weight: 400;
        color: #222222;
        line-height: 24px;
      }
    }
  }
}</style>
