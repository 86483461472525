<template>
  <div class="payStepBox">
    <div class="stepOne stepopt active">
      <div class="round">
        <span>{{ $fanyi('产品费用支付') }}</span>
      </div>
      <!-- 1 -->
      <div class="transverseLine"></div>
      <div class="kk" :class="{ transverseLine: $parent.step == 2 || !isPeiSong }"></div>
    </div>
    <div class="stepOne stepopt" :class="{ active: $parent.step == 2 || !isPeiSong }">
      <div class="round">
        <span>{{ $fanyi('运输到仓库') }}</span>
      </div>
      <!-- 2 -->
      <div class="transverseLine"></div>

      <div class="kk" :class="{
        transverseLine: $route.query.order_sn
          ? $parent.step == 2 && !isPeiSong
          : $parent.step == 2 || !isPeiSong,
      }"></div>
    </div>
    <div class="stepOne stepopt" :class="{ active: !isPeiSong }">
      <div class="round">
        <span>{{ $fanyi('配送单费用支付') }}</span>
        <!-- 3 -->
      </div>
      <div class="transverseLine"></div>
      <div class="kk" :class="{ transverseLine: $parent.flag }"></div>
    </div>
    <div class="stepOne stepopt" :class="{ active: $parent.step == 2 && !isPeiSong }">
      <div class="round">
        <span>{{ $fanyi('订单完成') }}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {}
  },
  components: {},
  computed: {
    isPeiSong() {
      return !this.$parent.isPeiSongDan
    },
  },
  created() {

  },
  methods: {},
}
</script>
<style lang="scss" scoped="scoped">
@import '../../../css/mixin.scss';

.payStepBox {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;

  .stepopt {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .round {
      width: 40px;
      height: 40px;
      background: #ffffff;
      border: 2px solid #e2e2e2;
      border-radius: 50%;
      position: relative;

      span {
        position: absolute;
        bottom: -41px;
        left: 50%;
        transform: translate(-50%, 0);
        white-space: nowrap;
      }

      &::after {
        content: '';
        width: 20px;
        height: 20px;
        background: #e2e2e2;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .transverseLine {
      width: 150px;
      height: 3px;
      background-color: #e2e2e2;
    }

    .kk {
      width: 150px;
      height: 3px;
      background-color: #e2e2e2;
    }

    &.active {
      .round {
        border-color: #ff730b;

        &:after {
          background-color: #ff730b;
        }
      }

      .transverseLine {
        background-color: #ff730b !important;
      }
    }
  }
}
</style>
